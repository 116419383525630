import { useEffect } from "react";
import Plausible from "plausible-tracker";

function usePageTracking() {
  useEffect(() => {
    const plausible = Plausible({
      domain: "ldc-zastitaknjiga.com",
    });

    plausible.trackPageview();
  }, []);
}

export { usePageTracking };
