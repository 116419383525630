import React from "react";

import "./base.css";
import styles from "./Layout.module.css";
import Header from "./Header";
import Footer from "./Footer";

function Layout({ location, children }) {
  return (
    <div className={styles.container}>
      <Header path={location} />
      <h1>{location}</h1>
      {location?.includes("Proizvodi") && (
        <p>Cijene su izražene bez PDV-a.</p>
      )}
      {children}
      <Footer />
    </div>
  );
}

export default Layout;
