import React from "react";

import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <span className={styles.address}>
        <p><strong>Adresa</strong></p>
        <p>LDC obrt za trgovinu, proizvodnju, usluge i prijevoz</p>
        <p>Visočička 37, 10040 Zagreb, HR</p>
        <p><a href="https://goo.gl/maps/Wm4vePxk9T2WLzeb9">Pogledaj adresu na karti</a> <span role="img" aria-label="Map pin">📍</span></p>
      </span>
      <span className={styles.contact}>
        <p><strong>Kontakt</strong></p>
        <p>Lada Janković Markušić</p>
        <p>ladaldc@gmail.com</p>
        <p>+385 (0)1 292 4563</p>
      </span>
      <span className={styles.info}>
        <p><strong>Dodatne informacije</strong></p>
        <p>Ova stranica ne koristi kolačiće već privremeno pohranjivanje podataka, "session storage" vašeg pretraživača, za zapisivanje upita za narudžbu. Podaci se brišu kod zatvaranja prozora.</p>
        <p>Osobne podatke iz upita za ponudu koristimo samo kako bismo vas kontaktirali u vezi vašeg upita, nigdje ih ne pohranjujemo.</p>
        <p>Detalje o dostavi šaljemo po zaprimljenom upitu.</p>
      </span>
    </footer>
  );
}

export default Footer;
