import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";

import styles from "./Navigation.module.css";

import {
  OrderStateContext,
  OrderDispatchContext,
} from "../context/order-context";

function Navigation() {
  const { order } = useContext(OrderStateContext);
  const dispatch = useContext(OrderDispatchContext);
  const [numberOfItems, setNumberOfItems] = useState(0);

  useEffect(() => {
    const orderFromStorage = JSON.parse(localStorage.getItem("order"));
    if (!!orderFromStorage && order.length === 0) {
      dispatch({
        type: "LOAD_ORDER_FROM_STORAGE",
        payload: orderFromStorage,
      });
    }
  }, [dispatch, order]);

  useEffect(() => {
    const numberOfItems = order.reduce((sum, item) => (sum += item.qty), 0);
    setNumberOfItems(numberOfItems);
  }, [order]);

  return (
    <nav className={styles.navigation}>
      <ul className={styles.navigationList}>
        <li className={styles.navigationListItem}>
          <Link to="/">Proizvodi</Link>
        </li>
        <li className={styles.navigationListItem}>
          <Link to="/about-us">O nama</Link>
        </li>
      </ul>
      <Link className={styles.shopping} to="/shopping-cart">
        <span className={styles.itemsCounter}>
          Upit za ponudu ({numberOfItems})
        </span>
        <span
          className={styles.shoppingCart}
          role="img"
          aria-label="shopping cart"
        >
          📝
        </span>
      </Link>
    </nav>
  );
}

export default Navigation;
